<template>
  <div id="table" class="flex flex-col">
    <div class="-my-2 overflow-x-auto">
      <div class="py-2 align-middle inline-block min-w-full">
        <div
          class="overflow-hidden border-gray-200 sm:rounded-lg"
        >
          <table class="min-w-full divide-white-200">
            <thead>
            <tr>
              <th></th>
              <th v-for="th in headers" :key="th" :class="headerClasses">
                {{ th }}
              </th>
            </tr>
            </thead>
            <tbody class="bg-white divide-white-200">
            <template v-for="(person, rowindex) in persons">
              <template v-for="(dif, index) in person.specialities">
              <tr :key="'H'+person.id+index" :id="person.id + 'S' + index" :ref="`heads${person.id}`" class="hover:bg-blue-100" @click="showDetails(person, rowindex, index)">
                <td :class="rowClasses" @click="showDetails(person, rowindex, index)">
                  <svg v-if="person.specialities[index].open == true" class="faq_arrow" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M24 12.9405L22.12 11.0605L16 17.1672L9.88 11.0605L8 12.9405L16 20.9405L24 12.9405Z" fill="#0066FF"/>
                  </svg>
                  <svg v-if="person.specialities[index].open == false" class="faq_arrow" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 19.0595L9.88 20.9395L16 14.8328L22.12 20.9395L24 19.0595L16 11.0595L8 19.0595Z" fill="#0066FF"/>
                  </svg>
                </td>
                <td :class="rowClasses">
                  {{ person.names }}
                </td>
                <td :class="rowClasses" class="lowercase font-gunb-bold">
                  {{ person.primary_voivodeship }}
                </td>
                <td :class="numberClasses">
                  {{ person.decision_number }}
                </td>
                <td :class="rowClasses">
                  {{ dif.discipline }}
                </td>
                <td :class="rowClasses">
                  {{ dif.speciality }}
                </td>
                <td class="px-6 py-4 whitespace-no-wrap td-new">
                    <span
                      :class="[
                        dif.extent === 'bez ograniczeń' ? no_limits : limited
                      ]"
                    >
                      {{
                        dif.extent === 'bez ograniczeń'
                          ? dif.extent
                          : $t('limited')
                      }}
                    </span>
                </td>
              </tr>
              <tr :key="'P'+person.id+index" :id="person.id.toString() + index.toString()" :ref="`persons${person.id}`" class="tr-info">
                <td class="whitespace-pre-wrap text-sm leading-5 text-gray-700 td-new" colspan="7" >
                  <div class="items-start flex flex-wrap info-box">
                  <div class="w-1/2 in-box">
                    <table class="info-table">
                      <tr>
                        <td class="info-box-name_1" >Przynależność do izby samorządu zawodowego</td>
                        <td class="info-box-value_1">{{person.memberships[0]}}</td>
                      </tr>
                      <tr>
                        <td class="info-box-name_1">Tytuł zawodowy</td>
                        <td class="info-box-value_1">{{person.professional_title}}</td>
                      </tr>
                      <tr>
                        <td class="info-box-name_1">Stopień lub tytuł naukowy</td>
                        <td class="info-box-value_1">{{person.academic_degree}}</td>
                      </tr>
                      <tr><td><br></td><td><br></td></tr>
                      <tr><td><br></td><td><br></td></tr>
                      <tr><td class="info_link" colspan="2"><a v-on:click="openfaq()" href="#introduction">Zobacz jak interpretować te dane</a></td></tr>
                      <tr><td><br></td><td><br></td></tr>
                    </table>
                  </div>
                  <div class="w-1/2 in-box">
                    <table class="info-table">
                      <tr>
                        <td class="info-box-name">Kontakt</td>
                        <td class="info-box-value">{{person.phone_number}}</td>
                      </tr>
                      <tr>
                        <td></td>
                        <td class="info-box-value">{{person.email_address}}</td>
                      </tr>
                      <tr>
                        <td class="info-box-name">Adres</td>
                        <td class="info-box-value">{{person.street}} {{person.house_number}}{{ person.apartment_number}}</td>
                      </tr>
                        <tr>
                          <td></td>
                          <td class="info-box-value">{{person.zip_code}} {{person.post_office}}</td>
                      </tr>
                      <tr><td><br></td><td><br></td></tr>
                      <tr><td><br></td><td><br></td></tr>
                      <tr><td><br></td><td><br></td></tr>
                    </table>
                  </div>
                  </div>
                </td>
              </tr>
                </template>
            </template>
            <tr>
              <td colspan="8">
                <Pagination></Pagination>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from './Pagination'
import { mapGetters } from 'vuex'

export default {
  name: 'Table',
  shouldShow: false,
  components: { Pagination },
  data: function () {
    return {
      headerClasses:
        'px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-900 tracking-wider whitespace-pre-wrap th-new',
      rowClasses:
        'px-6 py-4 whitespace-pre-wrap text-sm leading-5 text-gray-700 td-new',
      numberClasses:
        'px-6 py-4 whitespace-pre-wrap text-sm leading-5 text-gray-700 text-center bottom-border',
      no_limits:
        'px-2 inline-flex text-xs leading-5 font-semibold text-black uppercase no_limits',
      limited:
        'px-2 inline-flex text-xs leading-5 font-semibold text-black uppercase limited',
      s_index: 0
    }
  },
  computed: {
    headers() {
      return JSON.parse(this.$t('table_headers').replace(/'/g, '"'))
    },
    ...mapGetters(
      {
      persons: 'professionalCards/mapResults'
    })
  },
  methods: {
    openfaq() {
      window.location = '#introduction'
      this.$root.$emit('openfaq')
    },
    showDetails(person, rowindex, index) {
      if (person.specialities[index].open === false) {
        this.$refs['persons' + person.id.toString()][index].style.display = 'table-row'
        this.$refs['heads' + person.id.toString()][index].style.background = '#E3EEFF'
      } else {
        this.$refs['persons' + person.id.toString()][index].style.display = 'none'
        this.$refs['heads' + person.id.toString()][index].style.background = 'white'
      }
      person.specialities[index].open = !person.specialities[index].open
      this.$forceUpdate()
    }
  }
}
</script>

<style>
.td-new {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  border-bottom: gray solid 1px;
}

.bottom-border {
  border-bottom: gray solid 1px;
}

.th-new {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #152D69;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.tr-info {
  height: 170px;
  display: none;
  box-sizing: border-box;
  isolation: isolate;
}

.info-box {
  background: #F0F6FF;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
}

.td-info {
  box-sizing: border-box;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
}
.info_link {
  color: #005CE6;
  text-align: center;
  font-weight: bold;
}

.info-table {
  width: 100%;
}

.info-box-name {
  text-align: right;
  font-weight: bold;
  width: 150px;
}

.info-box-name_1 {
  text-align: right;
  font-weight: bold;
  width: 250px;
}

.info-box-value {
  text-align: left;
  padding-left: 1rem;
}

.info-box-value_1 {
  text-align: left;
  padding-left: 1rem;
  width: 300px;
}

.no_limits{
 background: #E3EEFF;
 color: #005CE6;
 border-radius: 4px;
}

.limited {
  background: #E3EEFF;
  color: #005CE6;
  border-radius: 4px;
}

.in-box {
  padding-left: 20px;
  padding-top: 10px;
}

.font-gunb-bold{
  font-weight: bold;
}
</style>
